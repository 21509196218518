import { useCallback } from 'react';
import { useCreateNewProject, useMigrateProject, useLoadExistingProject } from '.';
import {
  useAppContext,
  setIsProjectSaved,
  setSkipSpellcheckValidation,
  showLoadingScreen,
  hideLoadingScreen,
} from '../../../context/app-context';
import { ErrorResponse } from '../../../global-types';
import { useSanitizeSearchUrl, useQueryParams, useSystemErrorHandling } from '../../../hooks';
import { config } from '../../../regional-config';
import { getProductId, getProductTypeCode, getProjectId, getProjectName, isLoggedIn } from '../../../utils';

/**
 *
 * @param isDataLoaded - Ref that is used to track when the card data has loaded.
 * @param dependenciesList - List of dependencies to be passed to the survey.
 * @returns
 */
export const useStartProject = (
  isDataLoaded: React.MutableRefObject<boolean>,
  dependenciesList,
): (() => Promise<void>) => {
  // Context Hooks
  const {
    appState: { productQuantity },
    appDispatch,
  } = useAppContext();

  // Utility Hooks
  const { onSystemError } = useSystemErrorHandling();
  const { isSearchUrlSanitized } = useSanitizeSearchUrl();
  const queryParams = useQueryParams();
  const projectId = getProjectId();

  const productTypeCode = getProductTypeCode();
  const projectName = getProjectName();

  const createNewProject = useCreateNewProject();
  const migrateProject = useMigrateProject();
  const loadExistingProject = useLoadExistingProject();
  const deliveryMode = config?.deliveryMode;

  const startProject = useCallback(async () => {
    if (!isSearchUrlSanitized) {
      return;
    }

    const migrateFromId = queryParams.get('migrateFrom');
    // Every time the project starts we set this to false
    setIsProjectSaved(appDispatch, false);
    try {
      showLoadingScreen(appDispatch, '');
      if (!projectId) {
        // If project doesn't exist, create a new project.
        await createNewProject(
          {
            name: projectName === 'Digital Greetings' ? 'POD Project' : projectName,
            product_id: getProductId(),
            project_type_code: productTypeCode === 'D' ? 'P' : productTypeCode,
            is_digital_fulfillment: productTypeCode === 'D',
            is_draft: isLoggedIn(),
            purchase_quantity: Number(productQuantity),
            delivery_mode: `${deliveryMode}`,
          },
          isDataLoaded,
        );
        return;
      }
      // When user returns to an existing project, ensure spellcheck validation is always enabled the first time
      setSkipSpellcheckValidation(appDispatch, false);
      if (migrateFromId) {
        migrateProject(migrateFromId, projectId);
      }

      loadExistingProject(projectId, isDataLoaded);
    } catch (errors) {
      hideLoadingScreen(appDispatch);
      const redirectDialog = (errors as ErrorResponse)?.status === 401;
      onSystemError(errors as ErrorResponse, { redirect: redirectDialog });
    }
  }, dependenciesList);

  return startProject;
};
