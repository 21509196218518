import { underlineText } from './underline-text';

/**
 * Highlights misspellings in a given fabric.Text object
 * @param textObject The fabric.Text object spelling suggestions.
 */
export const highlightTextMisspellings = (textObject: fabric.Text) => {
  if (!textObject.text || !textObject.data?.suggestions) {
    return;
  }

  const {
    text,
    data: { suggestions = [] },
  } = textObject;

  // Loop through the mispelled words and underline them
  suggestions.forEach((suggestion) => {
    let start = text.indexOf(suggestion.word);
    while (start !== -1) {
      // Loop to handle all occurrences of the misspelled word
      const end = start + suggestion.word.length;
      underlineText(textObject, start, end);
      start = text.indexOf(suggestion.word, end); // Search for the next occurrence
    }
  });
};
