import { CardFace } from '../../global-types';

/**
 * Gets the sum of the objects in a card face.
 * @param face - the card face
 * @returns the sum of the objects in the card face
 */

export const getSumCardFaceObjects = (face: CardFace): number => {
  const noRemovableTexts = face.Texts.filter((text) => !text.CanDeleteTextArea);
  const { PhotoZones, EditableAreas } = face;
  return PhotoZones.length + noRemovableTexts.length + EditableAreas.length;
};
