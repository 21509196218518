import { CanvasDataTypes, getGroupedItemByName } from '../canvas';
import { handleTemporaryTextEditor } from './handle-temporary-text-editor';

/**
 * Handler for when mouse target on canvas is a photoTextzone
 * @param photoTextZone selected phototextzone
 * @param canvas
 * @param DISPLAY_ZOOM_DEV
 * @param SAS_DYNAMIC_BUTTONS
 */
export const handlePhototextzoneSelection = (photoTextZone, canvas, DISPLAY_ZOOM_DEV, SAS_DYNAMIC_BUTTONS) => {
  const originalText = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, photoTextZone) as fabric.Textbox;
  // if the photoTextZone has text, handle temporary text editor to edit the text
  if (originalText.text) {
    handleTemporaryTextEditor(canvas, DISPLAY_ZOOM_DEV, photoTextZone, SAS_DYNAMIC_BUTTONS);
  }
};
