import React, { Suspense, lazy, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Toast } from '@hallmark/web.core.feedback.toast';
import { SystemFailureDialog } from '../../components/system-failure-dialog';
import { useAppContext } from '../../context/app-context';
import { setIsToasterClosed, setTriggeringElementRef } from '../../context/app-context/app-context-actions';
import { useInitializationDataContext } from '../../context/data-context';
import { RegionalCodesList } from '../../global-types';
import { useQueryParams, useConfirmationDialog, useEnabledRoutes } from '../../hooks';
import { useSystemFailureDialog } from '../../hooks/useSystemFailureDialog';
import { config } from '../../regional-config';
import { getMonolithUrl } from '../../utils';
import { isAnyOfRegions } from '../../utils/utility';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog';
import { Loader } from '../loader';
import { PrintOnDemandHeader } from '../print-on-demand-header';
import { RedirectDialog } from '../redirect-dialog';
import { Toolbar } from '../toolbar';
import { LayoutProps } from './layout-types';
import styles from './layout.module.scss';

/**
 * Lazy loading EditorMenu since it's
 * only needed for CE region
 */
const EditorMenuComponent = lazy(() =>
  import('../editor-menu/editor-menu').then((module) => ({
    default: module.EditorMenu,
  })),
);

/**
 * Suspense fallback component for CE region
 * EditorMenu
 */
const EditorMenuLoader = () => <div>Loading</div>;

export const Layout = ({
  children,
  hideToolbar,
  addToolbarClass,
  addToastClass,
  addLayoutClass,
  onToolbarItemClick,
}: LayoutProps) => {
  const showEditorMenu = isAnyOfRegions([RegionalCodesList.ce]);
  const shouldHideToolbar = showEditorMenu || hideToolbar;
  const { appState, appDispatch } = useAppContext();
  const history = useHistory();
  const { toaster, loader, isSystemErrorOpen, isRedirectDialogOpen, exitUrl } = appState;
  const {
    initializedDataState: { isUK },
  } = useInitializationDataContext();
  const classes = classNames(styles.layout, shouldHideToolbar && styles['no-toolbar'], addLayoutClass);
  const queryParams = useQueryParams();
  const { handleSystemFailureAction, handleSystemFailureCancel, handleSystemFailureClose } = useSystemFailureDialog();
  const { pathname } = useLocation();
  const {
    saveProjects: { isSavedProjectsEnabled },
  } = config;
  const { isEnabledRoutes } = useEnabledRoutes();

  const { isDialogOpen, setIsDialogOpen, dialogGoToHomePage, handleCloseDialog, discardChanges } =
    useConfirmationDialog();

  const closeToaster = () => {
    setIsToasterClosed(appDispatch);
    document.body.removeAttribute('data-dialog');
  };

  const onCloseApp = useCallback(
    (button: HTMLButtonElement | null) => {
      if (!isEnabledRoutes(['/edit/']) && isSavedProjectsEnabled) {
        discardChanges();
        return;
      }
      if (button) {
        queryParams.append('exitUrl', exitUrl);
        history.replace({ pathname, search: queryParams.toString() });
      }
    },
    [queryParams, isEnabledRoutes(['/edit/'])],
  );

  const goToHomePage = () => {
    if (!isEnabledRoutes(['/edit/']) && isSavedProjectsEnabled) {
      discardChanges();
      return;
    }
    queryParams.append('exitUrl', getMonolithUrl());
    history.replace({ pathname, search: queryParams.toString() });
  };

  const isEditor = useMemo(() => {
    return pathname.includes('/edit/');
  }, [pathname]);

  // All this section will be removed once saved projects goes to Prod
  const { t } = useTranslation();

  const dialogOnCloseApp = useCallback((button: HTMLButtonElement | null) => {
    if (button) {
      setTriggeringElementRef(appDispatch, button);
    }
    setIsDialogOpen(true);
  }, []);

  // End of section

  return (
    <div className={classes}>
      <PrintOnDemandHeader
        onClose={isSavedProjectsEnabled ? onCloseApp : dialogOnCloseApp}
        isEditor={isEditor}
        onReturnToHomePage={isSavedProjectsEnabled ? goToHomePage : dialogGoToHomePage}
        isUK={isUK}
      />
      <ConfirmationDialog
        domId="close-confirmation"
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={discardChanges}
        title={`${t('header.leavePersonalizationTitle')}`}
        contentText={`${t('header.leavePersonalizationContent')}`}
        actionButtonText={`${t('header.leavePersonalizationActionButtonText')}`}
        cancelButtonText={`${t('header.leavePersonalizationCancelButtonText')}`}
      />
      {isSystemErrorOpen && (
        <SystemFailureDialog
          isOpen={isSystemErrorOpen}
          actionButton={handleSystemFailureAction}
          cancelButton={handleSystemFailureCancel}
          onClose={handleSystemFailureClose}
        />
      )}
      {isRedirectDialogOpen && <RedirectDialog />}
      <Loader isLoading={loader.isLoading} title={loader.title} />
      {toaster.isOpen && (
        <Toast
          variant={toaster.variant}
          isOpen={toaster.isOpen}
          title={toaster.title}
          onClose={closeToaster}
          addClass={addToastClass}
        >
          {toaster.children}
        </Toast>
      )}
      {showEditorMenu && (
        <Suspense fallback={EditorMenuLoader()}>
          <EditorMenuComponent />
        </Suspense>
      )}
      {!shouldHideToolbar && <Toolbar addClass={addToolbarClass} onToolbarItemClick={onToolbarItemClick} />}

      <div className={styles.content} data-testid="app-view">
        {children}
      </div>
    </div>
  );
};
