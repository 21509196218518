import { CardContextState } from '../context/card-context';
import { EditCanvasJson } from '../global-types/canvas';
import { CardType } from '../global-types/card';
import { bleedInPixelsNoScale, helperSettingsConfig, getBleed, CanvasDataTypes } from '../utils';

export const useCanvasScalingFactor = (
  canvasJson: EditCanvasJson,
  faceType: string | null = null,
  cardState: CardContextState,
  projectTypeCode: string,
) => {
  const canvas = structuredClone(canvasJson);
  const noBleed = cardState.isChocolate;

  // on chocolate cards the bleed is not removed, in order to see the chocolate border
  // on normal postcards the bleed is not removed either, because the images have no bleed

  const bleed = getBleed({ projectTypeCode, cardState, faceType });

  if (canvas && canvas.backgroundImage) {
    (canvas.backgroundImage.width as number) /=
      helperSettingsConfig.scalingFactor * (canvas.backgroundImage.scaleX as number);
    (canvas.backgroundImage.height as number) /=
      helperSettingsConfig.scalingFactor * (canvas.backgroundImage.scaleY as number);

    // on postcards there's no bleed so we don't need to remove it from the background
    canvas.backgroundImage.left = -bleed;
    canvas.backgroundImage.top = -bleed;

    const imgSrc = canvas.backgroundImage.src?.split('?w=')[0];
    canvas.backgroundImage.src = `${imgSrc}?w=${canvas.backgroundImage.width}`;

    canvas.objects = canvas.objects.map((obj) => {
      const canvasObject = obj;
      if (canvasObject.type === 'path') {
        (canvasObject.scaleX as number) /= helperSettingsConfig.scalingFactor;
        (canvasObject.scaleY as number) /= helperSettingsConfig.scalingFactor;
      } else if (canvasObject.type === 'textbox') {
        ((canvasObject as fabric.Textbox).fontSize as number) /= helperSettingsConfig.scalingFactor;
        (canvasObject.width as number) /= helperSettingsConfig.scalingFactor;
        (canvasObject.height as number) /= helperSettingsConfig.scalingFactor;
      } else {
        (canvasObject.scaleX as number) /= helperSettingsConfig.scalingFactor;
        (canvasObject.scaleY as number) /= helperSettingsConfig.scalingFactor;

        if ('src' in canvasObject) {
          /* eslint prefer-destructuring: ["error", {AssignmentExpression: {array: false}}]*/
          canvasObject.src = canvasObject.src?.split('?w=')[0];

          if (canvasObject.data?.type === CanvasDataTypes.UserImage || canvasObject.name === 'decoration') {
            canvasObject.src = `${canvasObject.src}`;
          } else if (canvasObject.width !== null) {
            canvasObject.src = `${canvasObject.src}?w=${canvasObject.width}`;
          }
        }
      }

      if (canvasObject.clipPath) {
        (canvasObject.clipPath.scaleX as number) /= helperSettingsConfig.scalingFactor;
        (canvasObject.clipPath.scaleY as number) /= helperSettingsConfig.scalingFactor;
        canvasObject.clipPath.left =
          ((canvasObject.clipPath.left as number) - (projectTypeCode === CardType.SAS ? 0 : bleedInPixelsNoScale())) /
          helperSettingsConfig.scalingFactor;
        canvasObject.clipPath.top =
          ((canvasObject.clipPath.top as number) - (projectTypeCode === CardType.SAS ? 0 : bleedInPixelsNoScale())) /
          helperSettingsConfig.scalingFactor;
      }

      if (noBleed) {
        (canvasObject.left as number) /= helperSettingsConfig.scalingFactor;
        (canvasObject.top as number) /= helperSettingsConfig.scalingFactor;
      } else {
        canvasObject.left =
          ((canvasObject.left as number) - (projectTypeCode === CardType.SAS ? 0 : bleedInPixelsNoScale())) /
          helperSettingsConfig.scalingFactor;
        canvasObject.top =
          ((canvasObject.top as number) - (projectTypeCode === CardType.SAS ? 0 : bleedInPixelsNoScale())) /
          helperSettingsConfig.scalingFactor;
      }
      return canvasObject;
    });
  }
  return canvas;
};
