import { Contact } from '../../components/address-book/address-book-types';
import { Font, InitializationData, QuickAddressesResponse } from '../../global-types';
import { AddressResponseData, Address, DigitalAddress } from '../../global-types/addresses';
import { actionTypes, Dispatch, UpdateOptions } from './data-context-types';

export const setInitializationData = (dispatch: Dispatch, initData: InitializationData) => {
  dispatch({
    type: actionTypes.SET_INIT_DATA,
    payload: initData,
  });
};

export const setFonts = (dispatch: Dispatch, fonts: Font[]) => {
  dispatch({
    type: actionTypes.SET_FONTS,
    payload: fonts,
  });
};

export const updateFontState = (dispatch: Dispatch, options: UpdateOptions) => {
  dispatch({
    type: actionTypes.UPDATE_FONT_STATE,
    payload: options,
  });
};

export const updateDigitalAddress = (dispatch: Dispatch, address: DigitalAddress | Address) => {
  dispatch({
    type: actionTypes.UPDATE_DIGITAL_ADDRESS,
    payload: address,
  });
};

export const updateAddresses = (
  dispatch: Dispatch,
  addresses: { recipient?: AddressResponseData | Address; sender?: AddressResponseData | Address },
) => {
  dispatch({
    type: actionTypes.UPDATE_ADDRESSES,
    payload: addresses,
  });
};

export const cleanAddresses = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.CLEAN_ADDRESSES,
  });
};

export const updateContacts = (dispatch: Dispatch, contacts: Contact[]) => {
  const payload = contacts != null ? contacts : [];
  dispatch({
    type: actionTypes.UPDATE_CONTACTS,
    payload,
  });
};

export const appendContacts = (dispatch: Dispatch, contacts: Contact[]) => {
  dispatch({
    type: actionTypes.APPEND_CONTACTS,
    payload: contacts,
  });
};

export const setContactPaging = (
  dispatch: Dispatch,
  paging: NonNullable<QuickAddressesResponse['data']>['paging'] | null,
) => {
  dispatch({
    type: actionTypes.SET_CONTACT_PAGING,
    payload: paging,
  });
};

export const resetDataContext = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.RESET_DATA_CONTEXT,
  });
};

export const setLineItemUUID = (dispatch: Dispatch, UUID: string) => {
  dispatch({
    type: actionTypes.SET_LINE_ITEM_UUID,
    payload: UUID,
  });
};

export const updateProjectName = (dispatch: Dispatch, projectName: string) => {
  dispatch({
    type: actionTypes.UPDATE_PROJECT_NAME,
    payload: projectName,
  });
};
