import { fabric } from 'fabric';
import { iconDefaultOffset, iconDefaultSize } from '../../../constants';
import { CanvasDataTypes } from '../../../utils/canvas';
import { handleImageWithIconMoves } from './handle-image-with-icon-moves';
import { handleImageWithIconResizes } from './handle-image-with-icon-resizes';

export enum IconsTypes {
  LowRes = 'lowRes',
}

type AddIconOptions = {
  iconOffset?: number;
  isStaticIcon?: boolean;
  isLowResIcon?: boolean;
  onIconAdded?: (icon: fabric.Image) => void;
  photoZone?: fabric.Object;
};

/**
 * Add an icon to the corner of a fabric object, such as a photo.
 * @param canvas - fabric canvas
 * @param parentObject - fabric object
 * @param iconPath - path to the icon
 * @param iconType - type of icon
 * @param options - additional options for the icon
 */
export const addIconToObject = (
  canvas: fabric.Canvas,
  object: fabric.Object,
  iconPath: string,
  iconType: string,
  options: AddIconOptions = {},
): void => {
  const { iconOffset = iconDefaultOffset, isStaticIcon, isLowResIcon, onIconAdded, photoZone } = options;

  fabric.Image.fromURL(iconPath, (icon) => {
    const iconSize = iconDefaultSize;
    icon.set({
      selectable: false,
      originX: 'center',
      originY: 'center',
      data: {
        type: CanvasDataTypes.PhotoIcon,
        removableOnSave: true,
        parentName: object.name,
      },
    });

    if (object.left && object.top && !photoZone) {
      icon.set({
        left: object.left + iconOffset,
        top: object.top + iconOffset,
      });
    } else if (photoZone) {
      icon.set({
        left: (photoZone.left as number) + iconOffset,
        top: (photoZone.top as number) + iconOffset,
      });
    }

    icon.scaleToWidth(iconSize);
    icon.scaleToHeight(iconSize);

    handleImageWithIconResizes(icon, object, iconOffset, isStaticIcon, isLowResIcon);
    if (!isStaticIcon) {
      object.on('moving', () => handleImageWithIconMoves(icon, object, iconOffset));
      object.on('rotating', () => handleImageWithIconMoves(icon, object, iconOffset));
    }
    object.on('scaling', () => handleImageWithIconResizes(icon, object, iconOffset, isStaticIcon, isLowResIcon));
    object.set({ data: { ...object.data, iconType: iconType } });
    canvas.add(icon.bringToFront());
    if (onIconAdded) {
      onIconAdded(icon);
    }
  });
};
