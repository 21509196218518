import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { IconPosition, TextLink } from '@hallmark/web.core.buttons.text-link';
import { Icon } from '@hallmark/web.core.display.icon';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { BrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useInitializationDataContext } from '../../../context/data-context';
import { getLoginUrl, isLoggedIn } from '../../../utils';
import { removeBeforeUnloadEvent } from '../../../utils';
import { AddressBookButtonProps } from '../address-form-types';
import styles from '../address-form.module.scss';

export const AddressBookButton = ({ click }: AddressBookButtonProps): React.ReactElement => {
  const {
    initializedDataState: { data: initializedData, lineItemUUID },
  } = useInitializationDataContext();

  const { t } = useTranslation();
  const loginRedirectUrl = getLoginUrl(
    initializedData?.project_id as string,
    initializedData?.account_id as string,
    lineItemUUID,
  );
  const isLogged = isLoggedIn();

  return (
    <>
      {!isLogged ? (
        <Button click={removeBeforeUnloadEvent} addClass={styles['login-button']} mode={ButtonModes.Custom}>
          <TextLink
            title={`${t('AddressBookButton.signIn')}`}
            url={loginRedirectUrl}
            icon={IconNames.AddressBookBold}
            color={BrandColors.Blue}
            iconPosition={IconPosition.Left}
            ariaLabel="AddressBookSignInLink"
          />
        </Button>
      ) : (
        <button
          onClick={click}
          className={styles['open-address-book-button']}
          type="button"
          data-testid="address-book-button"
          aria-label="AddressBookButton"
        >
          <div className={styles['open-address-book-button-container']}>
            <div className={styles['open-address-book-button-icon-container']}>
              <Icon size={40} name={IconNames.AddressBookBold} />
            </div>
            <div className={styles['open-address-book-button-text-container']}>
              <Typography htmlTagOverride={HtmlTagOverrides.Div} variant={TypographyVariants.BodyBold}>
                {t('AddressBookButton.addressBookTitle')}
              </Typography>
              <Typography
                addClass={styles['open-address-book-button-text-label']}
                htmlTagOverride={HtmlTagOverrides.Div}
                variant={TypographyVariants.Body}
              >
                {t('AddressBookButton.addressBookLabel')}
              </Typography>
            </div>
            <div className={styles['open-address-book-caret-container']}>
              <Icon color={BrandColors.Black} size={16} name={IconNames.ArrowsCaretUp} />
            </div>
          </div>
        </button>
      )}
    </>
  );
};
