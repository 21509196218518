import { useAnalyticsContext } from '../../../context/analytics-context';
import { setIsWamDrawerOpen, useAppContext } from '../../../context/app-context';
import { useFeatureFlags } from '../../../hooks';
import { CanvasDataTypes, getGroupedItemByName, getObjectByName, handleTemporaryTextEditor } from '../../../utils';

/**
 * Custom hook for S&S cards to handle all related functionality when user clicks on 'Type a message' or 'Add handwriting' buttons in a
 * phototextzone
 * @returns functions to handle typing or uploading a handwritten message to a phototextzone in S&S cards
 */
export const usePhotoTextZoneButtons = () => {
  const { firstElementSelected, updateEditFormats } = useAnalyticsContext();
  const { SAS_DYNAMIC_BUTTONS } = useFeatureFlags();
  const { appDispatch } = useAppContext();

  /**
   * Handle type a message functionality: find linked phototextzone, handle temporary textbox and update
   * text editition history for analytics tracking
   * @param canvas
   * @param canvasRef
   * @param button phototextzone's 'type a message' button
   */
  const handleTypeAMessage = (
    canvas: fabric.Canvas,
    canvasRef: React.RefObject<fabric.Canvas>,
    button: fabric.Object,
    isMobileZoomEnabled = false,
  ) => {
    if (canvas && canvasRef.current) {
      // handle temporary textbox
      const photoTextZone = getObjectByName(button.data.photoTextZoneId, canvasRef.current);
      handleTemporaryTextEditor(
        canvasRef.current,
        isMobileZoomEnabled,
        photoTextZone as fabric.Group,
        SAS_DYNAMIC_BUTTONS,
      );
      // check if text has been previously edited
      const originalTextbox =
        photoTextZone?.type === 'group'
          ? (getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, photoTextZone as fabric.Group) as fabric.Text)
          : null;
      const isTamEdited = (originalTextbox as fabric.Text)?.text?.length;

      // keep track of text edits for analytics
      updateEditFormats({ eventAction: isTamEdited ? 'format' : 'add' });
      if (!firstElementSelected.current) {
        updateEditFormats({ eventAction: 'start' });
        firstElementSelected.current = true;
      }
    }
  };

  /**
   * Handle add handwriting functionality: find linked zone to set it as active and open WAM upload drawer.
   * @param canvas
   * @param canvasRef
   * @param button phototextzone's 'add handwriting' button
   */
  const handleAddHandwriting = (
    canvas: fabric.Canvas,
    canvasRef: React.RefObject<fabric.Canvas>,
    button: fabric.Object,
  ) => {
    if (canvas && canvasRef.current) {
      const photoTextZone = getObjectByName(button.data.photoTextZoneId, canvasRef.current);
      canvasRef.current.setActiveObject(photoTextZone as fabric.Object);
      setIsWamDrawerOpen(appDispatch);
      updateEditFormats({ eventAction: 'add' });
    }
  };

  return { handleTypeAMessage, handleAddHandwriting };
};
