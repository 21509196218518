import i18next from 'i18next';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { addPhotoTrayImages, setIsToasterOpen } from '../../context/app-context';
import { Dispatch } from '../../context/app-context/app-context-types';
import { ApiResponse, CleanUpImageResponse, ImageResponse, LinkPhotoResponse } from '../../global-types';
import { CardType } from '../../global-types/card';
import { linkPhoto, cleanUpImage } from '../../services';
import { uploadPhotoS3 } from '../../services/image';
import { createSingleAssetUrl } from './image-create-single-asset-url';
import { validateUploadedImageFormat } from './validate-uploaded-image-format';

const uploadImage = async (
  imageToUpload: File,
  setError: (title: string, error: string) => void,
  setLoadingScreen: (message: string) => void,
  initializedData,
  appDispatch: Dispatch,
  t,
) => {
  const isPodProductCode =
    initializedData?.project_type_code === CardType.POD || initializedData?.project_type_code === CardType.DG;
  const formData = new FormData();
  formData.append('file', imageToUpload);
  formData.append('is_handwriting_image', `${initializedData?.project_type_code === CardType.SAS}`);
  formData.append('display_indicator', `${isPodProductCode}`);

  try {
    if (!initializedData?.project_id) throw new Error('No project id');

    const onFormatValidationError = () => {
      setIsToasterOpen(appDispatch, {
        variant: ToastVariants.Error,
        title: t('imageUploadDrawer.imageErrorTitle'),
        children: t('imageUploadDrawer.onlyImagesMessage'),
      });
    };

    await validateUploadedImageFormat(imageToUpload, onFormatValidationError, (exifStrippedImage) => {
      formData.set('file', exifStrippedImage);
    });

    setLoadingScreen('imageEditDrawer.uploadingImage');

    // Generate S3 asset URL
    const s3Url = await createSingleAssetUrl(imageToUpload);

    const uploadResp: ApiResponse<ImageResponse> = await (uploadPhotoS3(formData, false, s3Url) as Promise<
      ApiResponse<ImageResponse>
    >);
    if (!uploadResp.data) {
      throw new Error();
    }
    const uploadedImage = uploadResp.data;
    addPhotoTrayImages(appDispatch, [uploadedImage]);

    const linkPhotoResp: ApiResponse<LinkPhotoResponse> = await linkPhoto(
      { image_reference_id: uploadedImage.photo_tray_image_id },
      initializedData?.project_id as string,
    );
    if (linkPhotoResp && linkPhotoResp.meta.code !== 201) {
      throw new Error();
    }
    const imageId = linkPhotoResp.data?.image_id || '';
    if (isPodProductCode) {
      return { imageId: imageId, imageUrl: (linkPhotoResp.data as LinkPhotoResponse).image_url, cleanUpVersionId: '' };
    }
    setLoadingScreen('imageEditDrawer.handwrittenImage');
    const cleanUpResp: ApiResponse<CleanUpImageResponse> = await cleanUpImage(
      initializedData?.project_id as string,
      imageId,
      {},
    );
    if (cleanUpResp && cleanUpResp.meta.code !== 201) {
      throw new Error();
    }

    return {
      imageId: imageId,
      imageUrl: (cleanUpResp.data as CleanUpImageResponse).image_url,
      cleanUpVersionId: (cleanUpResp.data as CleanUpImageResponse).version_id,
    };
  } catch (e) {
    const MESSAGE_ERROR = i18next.t('imageUploadDrawer.uploadImageErrorMessage');

    setError('Image error', MESSAGE_ERROR);
    return { errorMessage: MESSAGE_ERROR };
  }
};

export default uploadImage;
