import { ImageType } from '../../global-types/images';
import { fixExifImageRotation } from './fix-exif-image-rotation';
import { isValidImage } from './is-valid-image';
import { mapImageType } from './map-image-type';

/**
 * Validates the format type of an image and fires an error callback if the image is an invalid type.  If JPEG, it will also fix the EXIF data.
 * @param imageFile An image file to validate
 * @param onImageFormatError Callback that fires when an image is the wrong format
 * @param onExifDataFixed Callback that fires when EXIF data is found and fixed
 * @returns void
 */
export const validateUploadedImageFormat = async (
  imageFile: File,
  onImageFormatError: () => void,
  onExifDataFixed: (imageBlob: Blob) => void,
) => {
  const { isValid, imageHeader } = await isValidImage(imageFile);

  if (!isValid || !imageHeader) {
    onImageFormatError();
    throw new Error('Invalid image format');
  }

  if (mapImageType(imageHeader) === ImageType.JPEG) {
    await fixExifImageRotation(imageFile, (imageBlob) => {
      if (imageBlob) {
        onExifDataFixed(imageBlob);
      }
    });
  }
};
