import React, { createContext, ReactNode, Reducer, useContext, useReducer } from 'react';
import { InitializationData } from '../../global-types';
import { initialDataState } from './data-context-state';
import { actionTypes, CreateContextProps, DataActions, InitializationDataContextState } from './data-context-types';

export const InitializationDataContext = createContext<CreateContextProps>(undefined);

const reducer: Reducer<InitializationDataContextState, DataActions> = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_INIT_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.SET_FONTS:
      return {
        ...state,
        fonts: action.payload,
      };
    case actionTypes.UPDATE_FONT_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.UPDATE_DIGITAL_ADDRESS:
      return {
        ...state,
        addressData: {
          recipient: null,
          sender: null,
          digital: action.payload,
        },
      };
    case actionTypes.UPDATE_ADDRESSES:
      return {
        ...state,
        addressData: {
          recipient: action.payload.recipient ?? state.addressData.recipient,
          sender: action.payload.sender ?? state.addressData.sender,
          digital: null,
        },
      };
    case actionTypes.CLEAN_ADDRESSES:
      return {
        ...state,
        addressData: {
          recipient: null,
          sender: null,
          digital: null,
        },
      };
    case actionTypes.UPDATE_CONTACTS:
      return {
        ...state,
        savedContacts: action.payload,
      };

    case actionTypes.APPEND_CONTACTS: {
      const contacts = state.savedContacts;

      // Assure contacts are unique
      const uniqueContacts = action.payload.filter(
        (contact) => !contacts.some((c) => c.contact_id === contact.contact_id),
      );

      return {
        ...state,
        savedContacts: [...contacts, ...uniqueContacts],
      };
    }
    case actionTypes.SET_LINE_ITEM_UUID:
      return {
        ...state,
        lineItemUUID: action.payload,
      };
    case actionTypes.UPDATE_PROJECT_NAME:
      return {
        ...state,
        data: {
          ...(state.data as InitializationData),
          name: action.payload,
        },
      };
    case actionTypes.SET_CONTACT_PAGING:
      return {
        ...state,
        contactPaging: action.payload,
      };
    case actionTypes.RESET_DATA_CONTEXT:
      return initialDataState;

    default:
      throw new Error(`Unhandled action type in init-data-context`);
  }
};

export const InitializationDataContextProvider = ({ children }: { children: ReactNode }) => {
  const [initializedDataState, initializationDataDispatch] = useReducer(reducer, initialDataState);
  return (
    <InitializationDataContext.Provider value={{ initializedDataState, initializationDataDispatch }}>
      {children}
    </InitializationDataContext.Provider>
  );
};

// Hooks
export const useInitializationDataContext = () => {
  const context = useContext(InitializationDataContext);
  if (context === undefined) {
    throw new Error('useInitDataContext must be used within InitializationDataContext');
  }

  return context;
};
