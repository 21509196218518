import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from '@hallmark/web.core.forms.radio-button';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { EnvelopeRadioGroupProps } from '../address-form-types';
import styles from '../address-form.module.scss';

/**
 * This component handles the envelope part of the address form.
 * Contains a selection of the envelope based on radio buttons
 */
export const EnvelopeRadioGroup = ({ register }: EnvelopeRadioGroupProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.addressing}>
      <Typography variant={TypographyVariants.Subtitle} htmlTagOverride={HtmlTagOverrides.H2} addClass={styles.title}>
        {t('addressing.title')}
      </Typography>
      <Typography variant={TypographyVariants.Body}>{t('addressing.description')}</Typography>
      <div role="radiogroup" aria-labelledby="radio_button_group">
        <RadioButton
          domId="blank-envelope-card"
          value="blank"
          addClass={styles['radio-card']}
          register={register('envelope', {
            required: true,
          })}
        >
          {t('addressing.blankEnvelope')}
        </RadioButton>
        <RadioButton
          addClass={styles['radio-card']}
          domId="return-envelope-card"
          value="return"
          register={register('envelope', {
            required: true,
          })}
        >
          {t('addressing.returnEnvelope')}
        </RadioButton>
      </div>
    </div>
  );
};
