import { fabric } from 'fabric';
import { addIconsToLowResImages } from '../../../card-editor/utils/index';

export const handleOrderChange = (order: number, canvas: fabric.Canvas) =>
  new Promise((resolve, reject) => {
    const activeObject = canvas.getActiveObject();
    if (!activeObject) {
      return reject(new Error('No active object found'));
    }

    activeObject.moveTo(order);

    // NOTE: This was added here to fix the icons not showing above the image.
    // v6 of fabric, this would be handled by <object>.on('moved', () => {})
    // v5 of fabric doesn't provide this event
    addIconsToLowResImages(canvas, (icon) => {
      const objectTop = activeObject.top ?? 0;
      const objectLeft = activeObject.left ?? 0;

      const iconTop = icon.top ?? 0;
      const iconLeft = icon.left ?? 0;

      // If the icon is outside the canvas, move it back inside
      if (objectTop < 0) {
        icon.top = iconTop + Math.abs(objectTop);
      }

      if (objectLeft < 0) {
        icon.left = iconLeft + Math.abs(objectLeft);
      }

      icon.setCoords();

      resolve(void 0);
    });
  });
