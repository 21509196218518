import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { fabric } from 'fabric';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { useCloseFontDrawer } from '../../../components/card-controls/font-drawer/hooks/useCloseFontDrawer';
import { useAnalyticsContext } from '../../../context/analytics-context';
import { setIsToasterOpen, setIsSpellcheckDialogOpen, useAppContext } from '../../../context/app-context';
import { storePreviews } from '../../../context/app-context/app-context-actions';
import { useCardContext } from '../../../context/card-context';
import { useInitializationOnGoingEventsContext, addOnGoingEvent } from '../../../context/on-going-events-context';
import { resetOnGoingEvents } from '../../../context/on-going-events-context/on-going-events-context-actions';
import { useSaveProject, useIsDigitalGreeting, useFeatureFlags } from '../../../hooks';
import { useActiveCanvas } from '../../../hooks';
import { config } from '../../../regional-config';
import { CanvasDataTypes, resetAllTextsHighlight, validateSpelling } from '../../../utils';
import { highlightMustEditText } from '../../../utils/utility';
import { Zone } from '../editor-types';
import { getMandatoryEditTextAreas } from '../utils/get-mandatory-edit-text-areas';
import { setEnvelopeSearchParam } from '../utils/search-param-utils';

/**
 * useHandleProjectDone is a hook that handles the completion of a card project.
 * It checks if there are fields that need to be completed before moving to the next view.
 *
 * @param skipValidation - A ref object that indicates whether to skip validation.
 * @param validateUserZones - A function that checks if the user has edited the necessary zones.
 * @param uneditedZones - An array of zones that haven't been edited.
 * @param isOneToMany - A boolean indicating whether the card is 1:Many type.
 * @param handleIndexChange - A function that handles the changing of the Card Face.
 *
 * @returns A function that can be invoked when the card project is done.
 */

export const useHandleProjectDone = (
  skipValidation: React.MutableRefObject<boolean>,
  validateUserZones: () => boolean,
  uneditedZones: Zone[],
  isOneToMany: boolean,
  handleIndexChange: (newIndex: number, newActiveCardIndex?: number) => void,
  ignoreSpellcheckValidation: React.MutableRefObject<boolean>,
) => {
  // Initialize hooks and variables
  const { t } = useTranslation();
  const { saveProject } = useSaveProject();
  const {
    appState: { previewImages },
    appDispatch,
  } = useAppContext();
  const {
    trackClickAddressRecipient,
    trackUneditedZoneError,
    trackEmailDeliveryRecipient,
    trackUneditedTextBoxWarningTag,
  } = useAnalyticsContext();
  const {
    onGoingEventsState: { onGoingEvents },
    onGoingEventsDispatch,
  } = useInitializationOnGoingEventsContext();
  const { IS_SPELLCHECK_ENABLED } = useFeatureFlags();
  const isDigitalGreeting = useIsDigitalGreeting();
  const { search } = useLocation();
  const history = useHistory();
  const {
    cardState: { cardFacesList },
  } = useCardContext();
  const canvas = useActiveCanvas();
  const { onFontDrawerSubmit } = useCloseFontDrawer();
  const {
    cardEditor: { lastCardFaceRoute },
    skipAddressView,
  } = config;

  return useCallback(() => {
    // if the font drawer is open, close it so it can save current edits
    onFontDrawerSubmit(canvas);

    const showErrorToastAndSetSkipValidation = (titleKey: string, descriptionKey: string, shouldSkip: boolean) => {
      handleIndexChange(0, 0);
      setIsToasterOpen(appDispatch, {
        variant: ToastVariants.Error,
        title: t(titleKey),
        children: t(descriptionKey),
      });
      skipValidation.current = shouldSkip;
    };

    // If spellcheck is enabled and there are texts with errors, open spellcheck validation dialog and return
    if (IS_SPELLCHECK_ENABLED && !ignoreSpellcheckValidation.current && !validateSpelling(cardFacesList)) {
      setIsSpellcheckDialogOpen(appDispatch, true);
      return;
    }

    // Check if there are any unedited zones and send warning to analytics if there are
    if (uneditedZones.length > 0) {
      const statusMessage = `${t('editableFieldsError.toastErrorTitle')} | ${t(
        'editableFieldsError.toastErrorDescription',
      )}`;

      const uneditedPhotoZone = uneditedZones.find(({ zone }) => zone.data?.type === CanvasDataTypes.PhotoZone);
      const uneditedTextZone = uneditedZones.find(({ zone }) => zone.data?.type === CanvasDataTypes.EditableText);
      // Send warning to analytics
      if (uneditedPhotoZone) {
        addOnGoingEvent(onGoingEventsDispatch, statusMessage);
        trackUneditedZoneError(statusMessage, uneditedPhotoZone.zone as fabric.Object, uneditedPhotoZone.face);
      }
      if (uneditedTextZone) {
        addOnGoingEvent(onGoingEventsDispatch, statusMessage);
        trackUneditedZoneError(statusMessage, uneditedTextZone.zone as fabric.Object, uneditedTextZone.face);
      }
    }

    // Check if there are any fields that must be edited (MustEditTextArea: true)
    const fieldsToBeEdited = getMandatoryEditTextAreas(uneditedZones, cardFacesList);

    // If there are fields to be edited, show an error toast and set skipValidation to false
    if (fieldsToBeEdited.length > 0) {
      cardFacesList.forEach((face) => {
        face?.canvas.current?.on('after:render', () => highlightMustEditText(face?.canvas?.current));
      });
      addOnGoingEvent(onGoingEventsDispatch, t('fieldsToBeEditedError.toastErrorTitle'));
      showErrorToastAndSetSkipValidation(
        'fieldsToBeEditedError.toastErrorTitle',
        'fieldsToBeEditedError.toastErrorDescription',
        false,
      );
    }
    // If user zones do not validate and skipValidation is false, show an error toast and set skipValidation to true
    else if (!validateUserZones() && !skipValidation.current) {
      showErrorToastAndSetSkipValidation(
        'editableFieldsError.toastErrorTitle',
        'editableFieldsError.toastErrorDescription',
        true,
      );
    }
    // Otherwise, save the project and go to the next view
    else {
      // Remove highlight from texts so it isn't included in the saved personalization json
      if (IS_SPELLCHECK_ENABLED && ignoreSpellcheckValidation.current) {
        resetAllTextsHighlight(cardFacesList);
      }
      // only execute if executed a previous error toast and was added on going events
      if (onGoingEvents.length > 0) {
        trackUneditedTextBoxWarningTag(onGoingEvents);
      }
      if (previewImages.length > 0) {
        storePreviews(appDispatch, []);
      }
      saveProject({
        shouldRestoreCanvas: false,
        showLoadingScreen: true,
        generateFrontPreview: false,
        generateFEPreviews: true,
      })?.then(() => {
        if (isDigitalGreeting) {
          trackEmailDeliveryRecipient();
        } else {
          trackClickAddressRecipient();
        }
        // sets default value to the envelope if the card is 1:Many
        const searchParams = new URLSearchParams(search);
        setEnvelopeSearchParam(searchParams, search, isOneToMany, skipAddressView);
        // Push to next view
        history.push({
          pathname: `/card/customization/${lastCardFaceRoute}`,
          search: searchParams.toString(),
        });
        // Reset on-going events to empty array once the project is saved
        resetOnGoingEvents(onGoingEventsDispatch);
      });
    }
  }, [validateUserZones, skipValidation.current, isOneToMany, ignoreSpellcheckValidation.current]);
};
