import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAnalyticsContext } from '../context/analytics-context';
import { useAppContext } from '../context/app-context';
import { useInitializationDataContext } from '../context/data-context';
import { redirectToEdit } from '../utils';

/**
 * Custom hook to handle actions related to system failure dialog component.
 *
 * @returns {Object} - Returns an object containing handlers for system failure actions.
 * @returns {Function} handleSystemFailureAction - Handles the retry action when a system failure occurs.
 * @returns {Function} handleSystemFailureCancel - Handles the cancel action, navigating to the previous page.
 * @returns {Function} handleSystemFailureClose - Handles the close action, navigating to the previous page.
 */
export const useSystemFailureDialog = (historyProp?: any) => {
  const [retryTrackingTrigger, setRetryTrackingTrigger] = useState<string | null>(null);
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { appState } = useAppContext();
  const routerHistory = useHistory();
  const history = historyProp || routerHistory;
  const { search } = useLocation();
  const { trackRetryHandlerTriggered } = useAnalyticsContext();
  const { errorMessages } = appState;

  const handleSystemFailureAction = () => {
    if (errorMessages) {
      trackRetryHandlerTriggered('retry', errorMessages);
    }
    const { pathname } = history.location;
    const isEditorView = pathname.includes('edit');
    setTimeout(() => {
      if (isEditorView) {
        history.go(0);
      } else {
        if (initializedData?.project_id) {
          redirectToEdit(initializedData?.project_id, search);
        }
      }
    }, 1000);
  };

  const handleSystemFailureCancel = () => {
    setRetryTrackingTrigger('exit');
    setTimeout(() => {
      window.location.assign(document.referrer);
    }, 1000);
  };

  const handleSystemFailureClose = () => {
    setRetryTrackingTrigger('exit-x');
    setTimeout(() => {
      window.location.assign(document.referrer);
    }, 1000);
  };

  useEffect(() => {
    if (retryTrackingTrigger) {
      if (errorMessages) {
        trackRetryHandlerTriggered(retryTrackingTrigger, errorMessages);
      }
      setRetryTrackingTrigger(null);
    }
  }, [retryTrackingTrigger]);

  return { handleSystemFailureAction, handleSystemFailureCancel, handleSystemFailureClose };
};
