import { fabric } from 'fabric';
import { AddressForm } from '../../../global-types';
import colors from '../../../styles/util.scss';
import { CanvasDataTypes, getFontName } from '../../../utils';

export const textSettings = {
  fontSize: 15,
  textAlign: 'center',
  fontWeight: 'normal',
  fontFamily: getFontName(115),
  selectable: false,
  hasBorders: true,
  hoverCursor: 'initial',
  color: colors.black,
};
/**
 * Function that creates the rectangles that indicates the text position for the sender (return) address.
 * @param canvas the envelope canvas instance
 * @returns 3 fabric elements that looks like a rectangle, they are positioned in the top-left of the canvas.
 */
export function getSenderTextPlaceholders(canvas: fabric.Canvas) {
  const responsiveWidth = canvas.getWidth() * 0.22;
  const defaultSettings = {
    width: responsiveWidth,
    height: 10,
    fill: colors['medium-gray'],
    selectable: false,
    left: 16,
    hoverCursor: 'initial',
  };

  const settings = [
    {
      ...defaultSettings,
      top: defaultSettings.height,
    },
    {
      ...defaultSettings,
      top: defaultSettings.height * 3,
    },
    {
      ...defaultSettings,
      top: defaultSettings.height * 5,
    },
  ];

  return settings.map(
    (settings, index) =>
      new fabric.Rect({
        ...settings,
        data: {
          id: index,
          type: CanvasDataTypes.SenderBox,
        },
      }),
  );
}

type GetRecipientTextPlaceholdersParams = {
  /** The current canvas instance */
  canvas: fabric.Canvas;
  /** The html div element that contains the canvas, we use the container to maintain the desire aspect ratio for the envelope */
  canvasContainer: HTMLDivElement;
};
/**
 * Function that creates the rectangles that indicates the text position for the recipient address.
 * @param GetRecipientTextPlaceholdersParams
 * @returns 3 fabric elements that looks like a rectangle, they are positioned in the center of the canvas container.
 */
export function getRecipientTextPlaceholders({ canvasContainer }: GetRecipientTextPlaceholdersParams) {
  const containerWidth = canvasContainer.offsetWidth;
  const containerHeight = canvasContainer.offsetHeight;
  const centerCanvasX = containerWidth / 2;
  const centerContainerY = containerHeight / 2;
  const width = containerWidth * 0.38;
  const height = 12;
  const defaultSettings = {
    width,
    height,
    fill: colors['medium-gray'],
    selectable: false,
    hoverCursor: 'initial',
    left: centerCanvasX - width / 2,
  };
  const gap = 12;
  const settings = [
    { ...defaultSettings, top: centerContainerY - height - gap },
    { ...defaultSettings, top: centerContainerY },
    { ...defaultSettings, top: centerContainerY + height * 2 },
  ];
  return settings.map(
    (settings, index) =>
      new fabric.Rect({
        ...settings,
        data: {
          id: index,
          type: CanvasDataTypes.RecipientBox,
        },
      }),
  );
}

/** Parses the form values into the format of the first line for a given address */
export const getFirstLineText = (values: Pick<AddressForm, 'first_name' | 'last_name'>) =>
  `${values.first_name} ${values.last_name}`;

/** Parses the form values into the format of the second line for a given address */
export const getSecondLineText = (values: Pick<AddressForm, 'address_line_1'>) => values.address_line_1;

/** Parses the form values into the format of the third line for a given address */
export const getThirdLineText = (values: Pick<AddressForm, 'city' | 'state_code' | 'zip'>) =>
  `${values.city}, ${values.state_code ?? ''} ${values.zip}`;

/** Array that contains an array of keys from the address form,
 *  each array value represents the form values that should be rendered in that line
 * */
export const FormPreviewLines: (keyof AddressForm)[][] = [
  ['first_name', 'last_name'],
  ['address_line_1'],
  ['city', 'state_code', 'zip'],
];

export const fillTextFromBox = (box: fabric.Rect, canvas: fabric.Canvas, value: string) => {
  const text = new fabric.Text(value, {
    ...textSettings,
    top: box.top,
    left: box.left,
    data: {
      type: box.data.type,
      id: box.data.id,
    },
  });
  canvas.remove(box);
  canvas.add(text);
};

export const getTextLineFromFormValues = (lineId: number, data: Partial<AddressForm>) => {
  if (lineId === 0) {
    const { first_name = '', last_name = '' } = data;
    return getFirstLineText({ first_name, last_name });
  }
  if (lineId === 1) {
    const { address_line_1 = '' } = data;
    return getSecondLineText({ address_line_1 });
  }
  if (lineId === 2) {
    const { city = '', state_code, zip = '' } = data;
    return getThirdLineText({ city, state_code, zip });
  }
  return '';
};

export const getLinesToBeFilled = (values: AddressForm) => {
  const lines = Object.keys(values)
    .map((name) =>
      FormPreviewLines.findIndex((value) => value.includes(name as keyof AddressForm) && !!values[`${name}`]),
    )
    .filter((index) => index >= 0);

  return lines.filter((id, index) => lines.indexOf(id) === index);
};
