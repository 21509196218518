import { fabric } from 'fabric';
import warningIcon from '../../assets/warning-icon.svg';
import { addIconToObject, IconsTypes } from '../../components/card-editor/utils/add-icon-to-object';
import { CardFaceData } from '../../global-types';
import { CanvasDataTypes, hideMiddleControls, setPhotoZoneScaleRatio } from '../canvas';

/**
 * Fills a given photo-zone using the id. This will update the canvas with the given image
 * @param photoZoneId the id of the photo zone
 * @param image the image to be added
 * @param faces the faces of the card
 * @param onIconAdded the callback function to be called when the icon is clicked
 */
export const fillPhotoZone = (
  photoZoneId: string,
  image: fabric.Image,
  faces: CardFaceData[],
  onIconAdded?: (icon: fabric.Image) => void,
): void => {
  faces.forEach((face: CardFaceData) => {
    const canvas = face.canvas.current;
    //find the current PhotoZone
    const canvasObjects = canvas?.getObjects() ?? [];
    const currentPhotoZone = canvasObjects.find((object) => object.name === photoZoneId);

    if (currentPhotoZone) {
      //fills the PhotoZone
      const clipPath = new fabric.Rect({
        width: currentPhotoZone.width,
        height: currentPhotoZone.height,
        left: currentPhotoZone.left,
        angle: currentPhotoZone.angle,
        top: currentPhotoZone.top,
        absolutePositioned: true,
      });

      image.set({
        left: currentPhotoZone.left as number,
        top: currentPhotoZone.top,
        clipPath,
        data: {
          ...image.data,
          photoZoneId: photoZoneId,
          type: CanvasDataTypes.PhotoZoneImage,
        },
        angle: currentPhotoZone.angle,
        scaleX: setPhotoZoneScaleRatio(
          image.width as number,
          image.height as number,
          currentPhotoZone.width as number,
          currentPhotoZone.height as number,
        ),
        scaleY: setPhotoZoneScaleRatio(
          image.width as number,
          image.height as number,
          currentPhotoZone.width as number,
          currentPhotoZone.height as number,
        ),
        onSelect: () => {
          image.set({
            data: {
              ...image.data,
            },
          });
          return false;
        },
      });
      hideMiddleControls(image);

      canvas?.add(image);

      image.sendToBack();
      if (canvas) {
        // Add a warning icon to low the resolution image added in the photozone.
        addIconToObject(canvas as fabric.Canvas, image, warningIcon, IconsTypes.LowRes, {
          iconOffset: 35,
          isLowResIcon: true,
          onIconAdded,
          isStaticIcon: true,
          photoZone: currentPhotoZone,
        });
      }
      currentPhotoZone.set({ visible: false, data: { ...currentPhotoZone.data } });
      currentPhotoZone.data.hasContent = true;

      //orders the canvas
      canvasObjects.forEach((object) => {
        //the photo zone button
        if (object.name === `${photoZoneId}-button`) {
          object.set({ visible: false });
        }
        if (object.name === CanvasDataTypes.PhotoFrameName) {
          object.bringToFront();
        }
      });
      canvas?.setActiveObject(image);
    }
  });
};
