import React, { MutableRefObject } from 'react';
import { Canvas } from 'fabric/fabric-impl';
import { CardFaceData } from '../../global-types';
import { CardContextState } from '../card-context';
import { InitializationDataContextState } from '../data-context';

export type AnalyticsProviderProps = {
  children: React.ReactNode;
  shouldInjectScripts?: boolean;
};

export type PODProductInfoShown = {
  productID: string;
  productName: string;
  productType: string;
  productQuantity?: string;
};

export type GetPODProductTagProps = PODProductInfoShown;

export type PODProductTag = {
  product: {
    productInfo: [
      {
        productInfoShown: PODProductInfoShown;
      },
    ];
  };
};

export type CreateContextProps =
  | {
      firstElementSelected: MutableRefObject<boolean>;
      pageNavigationButtonClicked: MutableRefObject<string | null>;
      isReplacingImage: MutableRefObject<boolean>;
      isOnEmailSplashScreen: MutableRefObject<boolean>;
      trackStartFlow: (eventAction: string) => unknown;
      trackSignIn: (eventAction: string) => unknown;
      trackSaveLink: () => unknown;
      trackDeleteElement: () => unknown;
      trackClickAddressRecipient: () => unknown;
      trackClickNextToAddressSender: () => unknown;
      trackLoadPreview: () => unknown;
      trackChangeQuantityProjectSummary: () => unknown;
      trackClickPreviewMode: () => unknown;
      updateUploadedPhotos: (uploadedImage: number) => unknown;
      uploadedPhotos: MutableRefObject<number[]>;
      trackUploadedPhotos: () => unknown;
      trackEnvelopePrintChoice: (choice: string) => unknown;
      trackSendEmailNow: (evenAction: string) => unknown;
      trackUneditedZoneError: (statusMessage: string, object: fabric.Object, face: string) => unknown;
      trackUploadPhotoError: (errorMessage: string) => unknown;
      trackRetryHandlerTriggered: (eventAction: string, errorMessages: string[]) => unknown;
      trackReturnToEdit: () => unknown;
      trackAddToCart: () => unknown;
      trackAddToCartError: (errorMessage: string) => unknown;
      trackEditElement: () => unknown;
      trackObjectSelection: (targetObject: fabric.Object, actionType: string) => unknown;
      updateEditFormats: (editFormat: { [key: string]: string }) => void;
      resetEditFormats: (keepFormats?: { [key: string]: string } | null) => void;
      getEditFormats: () => void;
      trackExitFlow: () => unknown;
      trackLeaveProjectSummary: (eventAction: 'x' | 'exit') => unknown;
      trackPageNavigation: () => unknown;
      trackViewProjectSummary: () => unknown;
      trackPhotoUploadMethod: (eventAction: 'camera icon' | 'photo object') => unknown;
      trackDGPlayAnimation: () => unknown;
      trackSuccesfullySentEmail: () => unknown;
      trackViewEmailSplash: () => unknown;
      trackKeepShopping: () => unknown;
      trackEmailDeliveryRecipient: () => unknown;
      trackDGViewFacePreview: (isEnvelope: boolean) => unknown;
      trackEditProjectName: () => unknown;
      trackDefaultProjectName: () => unknown;
      trackExitSaveProject: (eventAction: 'defaultName' | 'editName', eventExitAction: 'exit' | 'exit-x') => unknown;
      trackWamPhotoUpload: (file: string) => unknown;
      trackAbandonOrDeleteProject: (eventAction: leaveAction) => unknown;
      trackEditProjectSummaryName: () => unknown;
      trackUpdatedProjectNameSuccess: () => unknown;
      trackRefresh: () => unknown;
      trackSaveQuickAddress: (isQuickSaveAddress: boolean) => unknown;
      trackClickPreviewCarouselImageTag: (isEnvelope: boolean, activeCardFace: CardFaceData) => void;
      trackTextEdits: (activeObject: fabric.Object) => unknown;
      trackSpellcheckDialog: (eventAction: 'fix' | 'ignore' | 'cancel') => unknown;
      trackSelectedQuickAddress: (formTitle: string) => unknown;
      trackWamTamReset: (resetButton: boolean | null) => unknown;
      trackUneditedTextBoxWarningTag: (statusMessage: string[]) => unknown;
      trackDeleteQuickAddress: (formTitle: string) => unknown;
    }
  | undefined;

type ModalEvent = {
  eventAction: string;
  eventName: string;
};

export type ModalEventType = {
  eventInfo: ModalEvent;
};

export type ModalEventUserType = {
  CID: string;
  authStatus: string;
  guestProfileID: string;
};

export type ModalType = {
  event: ModalEventType[];
  level1: string;
  level2: string;
  pageType: string;
  projectID?: string;
};

export type TagOptions =
  | GetAddNewColorOptions
  | DefaultProductOptions
  | GetAddToCartErrorOptions
  | GetPreviewModeOptions
  | GetDeleteElementOptions
  | GetImageFormatsOptions
  | GetObjectSelectionOptions
  | GetUneditedZoneErrorOptions
  | GetUploadPhotoOptions
  | GetRetryHandlerTriggeredOptions
  | GetTextFormatsOptions
  | GetUploadPhotoErrorOptions
  | GetStartFlowOptions;

export type DefaultProductOptions = {
  productName: string;
  productID: string;
  productType: string;
  projectID: string;
  productQuantity: string;
};

export type ParamsElementPosition = {
  cardState?: CardContextState;
  initializedDataState?: InitializationDataContextState;
  activeCardFace?: CardFaceData | undefined;
  canvas?: React.RefObject<Canvas> | undefined;
};

export type GetElementPositionProps = {
  paramsElement: ParamsElementPosition;
  object?: fabric.Object;
  face?: string;
};

export type BuildTagFunction = (tagType: string, options?: TagOptions, keysToDelete?: string[]) => void;
export type TrackFunction = (trackType: string) => void;

export interface TrackEventParams {
  dataLayerAvailable: boolean;
  buildTag: BuildTagFunction;
  debug?: boolean;
  track: TrackFunction;
}

export type TrackEventFunction = {
  tagType: string;
  trackType: string;
  options: TagOptions;
  params: TrackEventParams;
  keysToDelete?: string[];
};

export type GetPreviewModeOptions = {
  elementPosition: string;
  button: string;
} & DefaultProductOptions;

export type GetStartFlowOptions = {
  elementPosition: string;
  eventAction: string;
} & DefaultProductOptions;

export type GetSignInOptions = {
  elementPosition: string;
  eventAction: string;
} & DefaultProductOptions;

export type GetSelectElementOptions = {
  elementPosition: 'pre-defined photo' | 'outside-back';
  elementType: string;
} & DefaultProductOptions;

export type GetDeleteElementOptions = {
  elementPosition: string;
  elementType: string;
} & DefaultProductOptions;

export type GetEditElementOptions = {
  elementPosition: string;
  elementType: string;
  editType: string;
} & DefaultProductOptions;

export type GetAddPhotoOptions = {
  elementPosition: string;
  eventName: string;
} & DefaultProductOptions;

export type GetUploadPhotoOptions = {
  elementPosition: string;
  uploadedPhotos: number[];
} & DefaultProductOptions;

export type GetAddNewColorOptions = {
  elementPosition: string;
  elementFormat: string;
} & DefaultProductOptions;

export type GetInitializeEditTag = {
  currentCardFace: string;
  elementClicked: string;
} & DefaultProductOptions;

export type EditFormats = {
  textAlignment: string;
  addColor: string;
  color: string;
  fontName: string;
  fontSize: string;
  crop: string;
  size: string;
  rotate: string;
  order: string;
  move?: boolean | null;
  isTextModified?: boolean | null;
  eventAction?: string | null;
  replace?: string;
};

export type GetTextFormatsOptions = {
  elementPosition: string;
  elementType: string;
  isTextModified: boolean;
} & DefaultProductOptions &
  EditFormats;

export type GetImageFormatsOptions = {
  elementPosition: string;
  elementType: string;
  eventAction: string;
} & DefaultProductOptions &
  EditFormats;

export type GetObjectSelectionOptions = {
  elementPosition: string;
  elementType: string;
  actionType: string;
} & DefaultProductOptions;

export type GetRetryHandlerTriggeredOptions = {
  eventAction: string;
  errorMessages: string[];
} & DefaultProductOptions;

export type GetUploadPhotoErrorOptions = {
  elementPosition: 'pre-defined photo' | 'outside-back';
  eventAction: string;
  errorMessage: string;
} & DefaultProductOptions;

export type GetAddToCartErrorOptions = {
  errorMessage: string;
} & DefaultProductOptions;

export type GetViewProjectSummaryOptions = {
  eventAction: string;
} & DefaultProductOptions;

export type GetLeaveProjectSummaryOptions = {
  eventAction: string;
} & DefaultProductOptions;

export type DataDogVariables = {
  env: string | undefined;
  clientToken: string | undefined;
  applicationId: string | undefined;
  site: string | undefined;
};

export type GetUneditedZoneErrorOptions = {
  elementPosition: string;
  statusMessage: string;
  typeOfObjectEvent: { eventName: string; eventAction: string };
} & DefaultProductOptions;

export type GetChangeQuantityFromProjectSummaryOptions = {
  quantity: string;
} & DefaultProductOptions;

export type GetEnvelopePrintChoiceOptions = {
  eventAction: string;
} & DefaultProductOptions;

export type GetPageNavigation = {
  button: string;
  page: string;
} & DefaultProductOptions;

export type GetPhotoUploadMethodOptions = {
  elementPosition: string;
  /** camera icon refers to the photozone camera button. Photo object refers to the add photo button on toolbar */
  eventAction: 'camera icon' | 'photo object';
} & DefaultProductOptions;

export type GetDGPlayAnimationOptions = {
  elementPosition: string;
  isOnEmailSplashScreen: boolean;
} & DefaultProductOptions;

export type GetSendEmailNowOptions = {
  eventAction: string;
} & DefaultProductOptions;

export type GetEmailDeliveryOptions = {
  eventAction: string;
} & DefaultProductOptions;

export type getEditProjectNameOptions = {
  elementPosition: string;
  onSplashScreen?: boolean;
} & DefaultProductOptions;

export type GetSaveLinkOptions = {
  elementPosition: string;
  onSplashScreen?: boolean;
} & DefaultProductOptions;

export type GetExitSaveProjectOptions = {
  eventAction: string;
  eventExitAction: string;
} & DefaultProductOptions;

export type GetWamPhotoUploadTag = {
  imageSize: string;
  elementPosition: string;
} & DefaultProductOptions;

export type GetTamEditResetTag = {
  eventAction: string;
} & DefaultProductOptions;

export enum leaveAction {
  ABANDON = 'abandon',
  DELETE = 'delete',
}

export type GetAbandonOrDeleteProjectOptions = {
  eventAction: leaveAction;
} & DefaultProductOptions;

export type GetRefreshOptions = {
  elementPosition: string;
  eventAction: string;
  cardFacesList: CardFaceData[];
} & DefaultProductOptions;

export type GetSaveQuickAddress = {
  elementPosition: string;
  isSender: boolean;
} & DefaultProductOptions;

export type GetSelectedQuickAddress = {
  elementPosition: string;
  formTitle: string;
} & DefaultProductOptions;

export type getUneditedTextBoxWarning = {
  elementPosition: string;
  statusMessage: string[];
} & DefaultProductOptions;

export type GetDeleteQuickAddress = {
  elementPosition: string;
  formTitle: string;
} & DefaultProductOptions;

export type GetSpellcheckDialogOptions = {
  /** 'fix' if the user clicked the check spelling button, 'ignore' if the user clicked ignore or closed the dialog*/
  eventAction: 'fix' | 'ignore' | 'cancel';
  /** Number of times the spellcheck dialog has been called (so far at any given time)*/
  spellcheckCalls: number;
} & DefaultProductOptions;

export type Profile = {
  CID: string;
  authStatus: string;
  guestProfileID: string;
};

export type UserProfile = {
  profile: Profile;
};
