import { useCallback, useEffect, useState } from 'react';
import { config } from '../../../regional-config';
import { fetchUsStates } from '../../../services/address';
import { UsStateOptions } from '../address-form-types';
import { filterUsStates } from '../utils/filter-us-states';

export const useUsStateOptions = () => {
  const [usStateOptions, setUsStateOptions] = useState<UsStateOptions[] | []>([]);
  const { hasUsStates } = config;

  const fetchStateData = useCallback(() => {
    fetchUsStates().then((usStatesData) => {
      const filteredStateOptions = filterUsStates(usStatesData).map((state) => ({
        value: state.code,
        label: state.desc,
      }));
      setUsStateOptions(filteredStateOptions);
    });
  }, []);

  useEffect(() => {
    if (usStateOptions.length === 0 && hasUsStates) {
      fetchStateData();
    }
  }, []);

  return { usStateOptions };
};
