import { useEffect } from 'react';
import API from '@dotcom/api-middleware';
import { useAppContext } from '../context/app-context';
import { setCsrfToken } from '../context/app-context/app-context-actions';
import { useInitializationDataContext } from '../context/data-context';
import { getMonolithUrl } from '../utils';

type CsrfToken = {
  csrf_token?: string;
  error?: string;
  data?: {
    action?: string;
    csrf_token?: string;
    status?: number;
  };
};

const headers = {
  Accept: 'application/json',
};

export const useCsrfToken = () => {
  const service = new API(getMonolithUrl(), headers);
  service.updateDefaultConfig('withCredentials', true);

  const {
    appState: { csrfToken },
    appDispatch,
  } = useAppContext();

  const {
    initializedDataState: { isUS },
  } = useInitializationDataContext();

  useEffect(() => {
    if (!csrfToken && isUS) {
      service.get('/csrf-token').then((res: CsrfToken) => {
        if (res?.csrf_token) {
          const token = (res.data?.csrf_token || res.csrf_token) as string;
          setCsrfToken(appDispatch, token);
          return token;
        }
      });
    }
  }, []);

  return csrfToken;
};
