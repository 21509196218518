/**
 * Sets the 'envelope' search parameter based on the provided conditions.
 *
 * @param {URLSearchParams} searchParams - A URLSearchParams instance representing the current URL search parameters.
 * @param {string} search - The current URL search string.
 * @param {boolean} isOneToMany - A flag indicating if the card is of "one to many" type.
 * @param {boolean} skipAddress - A flag indicating if we skip address view and thus won't need an envelope param.
 */

export const setEnvelopeSearchParam = (
  searchParams: URLSearchParams,
  search: string,
  isOneToMany: boolean,
  skipAddress?: boolean,
) => {
  if (skipAddress) return;
  if (isOneToMany) {
    const match = search.match(/envelope=([^&]*)/);
    const envelopeValue = match ? match[1] : 'return';

    // Set 'envelope' parameter based on its current value.
    if (envelopeValue !== 'return') {
      searchParams.set('envelope', 'blank');
    } else {
      searchParams.set('envelope', 'return');
    }
  }
};
