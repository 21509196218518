import { isRegion } from '../../utils/utility';
import { InitializationDataContextState } from './data-context-types';

export const initialDataState: InitializationDataContextState = {
  initialized: false,
  fontFactor: 5.5,
  defaultSize: null,
  defaultColor: null,
  fonts: [],
  hasFonts: false,
  data: null,
  addressData: {
    recipient: null,
    sender: null,
    digital: null,
  },
  savedContacts: [],
  contactPaging: null,
  entryURL: '',
  lineItemUUID: null,
  isUS: process.env.REACT_APP_THEME === 'us',
  isUK: process.env.REACT_APP_THEME === 'uk',
  isCE: process.env.REACT_APP_THEME === 'ce',
  allRegions: process.env.REACT_APP_THEME === 'all',
  region: isRegion(process.env.REACT_APP_THEME) ? process.env.REACT_APP_THEME : undefined,
};
