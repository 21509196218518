import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLoadFontFaces } from '.';
import { useInitializationDataContext } from '../../../context/data-context';
import { setInitializationData } from '../../../context/data-context';
import { CardFace, InitBody, InitializationData } from '../../../global-types';
import { useQueryParams, useSystemErrorHandling } from '../../../hooks';
import { initialize } from '../../../services';
import { formatResponseData, validateImages } from '../../../utils';
import { replaceInitializationUrl } from '../utils/replaceInitializationUrl';

export const useCreateNewProject = () => {
  const { t } = useTranslation();
  const { onSystemError } = useSystemErrorHandling();
  const { initializationDataDispatch } = useInitializationDataContext();
  const queryParams = useQueryParams();
  const loadFontFaces = useLoadFontFaces();
  const history = useHistory();

  const createNewProject = async (initialzeRequestBody: InitBody, isDataLoaded: React.MutableRefObject<boolean>) => {
    // the ternary operators below are to allow digital greetings to function as a POD
    // card and still store the correct identifying data in the state
    const response = await initialize(initialzeRequestBody);
    // corrects the wrong information we've sent the backend on digital greetings cards below
    const formatedSkuData = formatResponseData(response.data as InitializationData);
    const faces = formatedSkuData?.variables?.template_data?.Faces as CardFace[];

    // confirm all card faces have valid background images
    const missingBackgroundImageErrorText = t('editorView.missingBackgroundImageError');
    validateImages(faces, onSystemError, missingBackgroundImageErrorText);

    // Add all font faces to the document
    await loadFontFaces(formatedSkuData);

    // Save the whole response for use later when/if needed
    setInitializationData(initializationDataDispatch, formatedSkuData);
    isDataLoaded.current = true;

    replaceInitializationUrl(formatedSkuData.project_id, queryParams, history);
  };

  return createNewProject;
};
