import React, { ReactElement, useState, useEffect, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import {
  IconButton,
  IconButtonBrandColors,
  IconButtonVariants,
  IconNames,
} from '@hallmark/web.core.buttons.icon-button';
import { Image } from '@hallmark/web.core.display.image';
import { Dialog } from '@hallmark/web.core.feedback.dialog';
import { DialogIconNames, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { ToastVariants } from '@hallmark/web.core.feedback.toast';
import { Carousel } from '@hallmark/web.core.surfaces.carousel';
import { SEO } from '@hallmark/web.page-components.seo';
import { BrandColors } from '@hallmark/web.styles.colors';
import { AddToCart } from '../../components/add-to-cart';
import { CardSelectBar } from '../../components/card-select-bar';
import { ECardLimitModal } from '../../components/ecard-limit-modal/ecard-limit-modal';
import { Layout } from '../../components/layout';
import { useAnalyticsContext } from '../../context/analytics-context';
import { hideLoadingScreen, showLoadingScreen, useAppContext, setIsToasterOpen } from '../../context/app-context';
import { setActiveCardIndex, useCardContext } from '../../context/card-context';
import { useInitializationDataContext } from '../../context/data-context';
import {
  CardFacePreviewImage,
  ApiResponse,
  ErrorResponse,
  CardType,
  AssetFormData,
  RecipientResponseData,
  ProjectTypeCode,
} from '../../global-types';
import { useBasket, useFeatureFlags, useLineItemUUID, useQueryParams, useSubmitOrder } from '../../hooks';
import { useSystemErrorHandling } from '../../hooks/useSystemErrorHandling';
import { getCurrentRegion } from '../../regional-config/get-regional-config';
import { saveRecipientAssets } from '../../services';
import { removeBeforeUnloadEvent, loadProjectDetails } from '../../utils';
import { useEditorNavigation } from '../editor/hooks/useEditorNavigation';
import { EmailSplash, NoCrNoH } from './fragments';
import styles from './preview.module.scss';
import { handleCloseAndKeepShopping, finalizeOrder } from './utils';
import { useLoadPreviewOnFrontend } from './utils/load-preview-on-frontend';

/**
 * Lazy loading Animation preview since it's
 * only needed for DGs
 */
const AnimationPreviewComponent = lazy(() =>
  import('../../components/animation-preview').then((module) => ({
    default: module.AnimationPreview,
  })),
);

/**
 * Suspense fallback component for DG
 * AnimationPreviewComponent
 */
const AnimationPreviewLoader = () => <div>Loading</div>;

const INITIAL_SLIDE_INDEX = 0;

export const PreviewView = (): ReactElement => {
  const {
    initializedDataState,
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const {
    cardDispatch,
    cardState: { coverPreviewUrl, cardFacesList },
  } = useCardContext();
  const {
    appState: { isSystemErrorOpen, isMobileApp, previewImages },
    appDispatch,
  } = useAppContext();
  const {
    pageNavigationButtonClicked,
    trackLoadPreview,
    trackReturnToEdit,
    trackDGPlayAnimation,
    trackSendEmailNow,
    trackSuccesfullySentEmail,
    trackDGViewFacePreview,
    trackClickPreviewCarouselImageTag,
  } = useAnalyticsContext();
  const { handleIndexChange } = useEditorNavigation();
  const { getPreviews } = useLoadPreviewOnFrontend();
  const [slideIndex, setSlideIndex] = useState(INITIAL_SLIDE_INDEX);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAnimationDialogOpen, setIsAnimationDialogOpen] = useState(false);
  // TODO: We can replace 'any' with new type when we stop using a mix of FE/BE previews
  const [images, setImages] = useState([] as CardFacePreviewImage[] | any[]);
  const [animationPreviewImages, setAnimationPreviewImages] = useState<string[]>([]);
  const [btlyUrl, setBtlyUrl] = useState('');
  const [isSendingemail, setIsSendingEmail] = useState(false);
  const [isNoCrNoHOpen, setIsNoCrNoHOpen] = useState(false);
  const [isNoCrNoHLoyaltyVisible, setIsNoCrNoHLoyaltyVisible] = useState(false);
  const [isEcardLimitReachedOpen, setIsEcardLimitReachedOpen] = useState(false);
  const [consumerReviewCheck, setConsumerReviewCheck] = useState(false);
  const { IS_H_PLUS_ENABLED } = useFeatureFlags();
  const { onSystemError } = useSystemErrorHandling();
  // we pass a lot of the basket data to the useSubmitOrder hook, but have to initialize it here to share the state with the hook
  const { addToCart, basket, recentBasket, basketError, closeModalOverride, keepShoppingOverride } = useBasket();
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = useQueryParams();
  useLineItemUUID();
  const { handleSubmit } = useSubmitOrder(images, setIsDialogOpen, addToCart);
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);

  const { t } = useTranslation();
  const projectId = initializedData?.project_id || '';
  const projectTypeCode = sessionStorage.getItem('lastProjectTypeCode');
  const isDigitalGreetings = projectTypeCode === CardType.DG;
  const quantity = queryParams.get('qty') ?? '1';
  const continueMessage = isDigitalGreetings ? t('previewView.sendEmailNow') : t('previewView.addToCart');
  const playAnimationMessage = isMobileApp ? t('previewView.playAnimationMobile') : t('previewView.playAnimation');
  const isNotNull = (value) => value != null && value !== '' && value !== undefined;
  const region = getCurrentRegion();

  type buttonProps = {
    click: () => void;
  };
  const ActionButton = ({ click }: buttonProps) => (
    <Button
      click={click}
      mode={ButtonModes.Primary}
      testId="action-button"
      addClass={styles['primary-button']}
      disabled={!consumerReviewCheck}
    >
      {t('previewView.actionButton')}
    </Button>
  );

  const CancelButton = ({ click }: buttonProps): ReactElement => (
    <Button click={click} mode={ButtonModes.TextLink} testId="cancel-button" addClass={styles['edit-button']}>
      {t('previewView.cancelButton')}
    </Button>
  );

  const BackToPreviewButton = ({ click }: buttonProps): ReactElement => (
    <Button click={click} mode={ButtonModes.Secondary} testId="back-to-preview-button" addClass={styles['edit-button']}>
      {t('previewView.backToPreviewButton')}
    </Button>
  );

  const sendEmailNow = async () => {
    const DGpreview = {
      name: 'Front',
      url: coverPreviewUrl,
    };
    setIsSendingEmail(true);
    showLoadingScreen(appDispatch, t('previewView.sendingEmailLoadingScreenTitle'));
    try {
      trackSendEmailNow(t('previewView.sendEmailNow'));
      // TODO: add call to send email here and get success response
      finalizeOrder({
        cardPreviewImages: [DGpreview as any],
        btlyUrl: btlyUrl,
        onNoHPlusNonSubscriberError: () => {
          setIsNoCrNoHOpen(true);
          hideLoadingScreen(appDispatch);
          setIsSendingEmail(false);
        },
        onCrMemberLimitRechedError: () => {
          setIsEcardLimitReachedOpen(true);
          hideLoadingScreen(appDispatch);
          setIsSendingEmail(false);
        },
        onNonCrMemberError: () => {
          setIsNoCrNoHLoyaltyVisible(true);
          setIsNoCrNoHOpen(true);
          hideLoadingScreen(appDispatch);
          setIsSendingEmail(false);
        },
        onDefaultError: (error: ErrorResponse) => {
          onSystemError(error);
        },
        initializedDataState,
        // TODO: Add feature flag here when we have it
        isHPlusEnabled: IS_H_PLUS_ENABLED,
      }).then(() => {
        setShowEmailConfirmation(true);
        trackSuccesfullySentEmail();
        hideLoadingScreen(appDispatch);
        setIsSendingEmail(false);
      });
    } catch (errors) {
      setIsToasterOpen(appDispatch, {
        title: t('systemSendEmailNowError.title'),
        children: t('systemSendEmailNowError.description'),
        variant: ToastVariants.Error,
      });
      hideLoadingScreen(appDispatch);
      setIsSendingEmail(false);
    }
  };

  const handlePlayAnimation = () => {
    loadProjectDetails(projectId).then((animationPanels) => {
      setAnimationPreviewImages([animationPanels.F.resize_urls.M, animationPanels.I.resize_urls.M]);

      setIsAnimationDialogOpen(true);
      trackDGPlayAnimation();
    });
  };

  const handleCloseAnimationDialog = () => {
    setIsAnimationDialogOpen(false);
  };

  const handleImageDotClick = (newIndex: number) => {
    const activeFace = cardFacesList[`${newIndex}`];
    trackClickPreviewCarouselImageTag(isEnvelope(newIndex), activeFace);
    setSlideIndex(newIndex);
  };

  const setAnalyticsIndex = (index: number) => {
    setSlideIndex(index);
    setActiveCardIndex(cardDispatch, index);
  };

  const isEnvelope = (index: number) => {
    // eslint-disable-next-line security/detect-object-injection
    return images[index].type === 'Envelope';
  };

  const handleNextButtonClick = async (index: number) => {
    if (index < images.length - 1) {
      pageNavigationButtonClicked.current = 'button';
      setAnalyticsIndex(index + 1);
      const activeFace = cardFacesList[`${index + 1}`];
      trackClickPreviewCarouselImageTag(isEnvelope(index + 1), activeFace);
    } else {
      if (isDigitalGreetings) {
        sendEmailNow();
      } else {
        setIsDialogOpen(true);
      }
    }
  };

  const handlePrevClick = (index: number) => {
    const activeFace = cardFacesList[`${index - 1}`];
    trackClickPreviewCarouselImageTag(isEnvelope(index - 1), activeFace);
    if (index !== 0) {
      setAnalyticsIndex(index - 1);
    }
  };

  const handleChangeSlide = (newIndex: number) => {
    isDigitalGreetings && trackDGViewFacePreview(isEnvelope(newIndex));
    if (newIndex !== slideIndex) setAnalyticsIndex(newIndex);
  };

  const loadPreviewOnFrontend = async () => {
    const previewImages = await getPreviews();
    setImages(previewImages);
    const frontFace = images[0];
    const firstIndex = frontFace?.faceNumber ? frontFace.faceNumber - 1 : 0;
    setActiveCardIndex(cardDispatch, firstIndex);
    if (!isDigitalGreetings) {
      hideLoadingScreen(appDispatch);
    }
    trackLoadPreview();
  };

  const goBack = () => {
    trackReturnToEdit();
    history.push({ pathname: `/card/customization/edit/${projectId}`, search });
    handleIndexChange(0, 0);
  };

  useEffect(() => {
    if (projectId) {
      // check that preview images have saved before loading them
      const previewImagesSaved = previewImages.length > 0;
      previewImagesSaved && loadPreviewOnFrontend();
    }
  }, [projectId, previewImages]);

  useEffect(() => {
    if (images.length >= 2 && isDigitalGreetings) {
      showLoadingScreen(appDispatch, 'Generating preview');
      const fetchDigitalAssets = async (frontUrl: string, insideUrl: string) => {
        // Make a request to the "/assets" endpoint only once, passing both URLs in the body of the request
        const assetFormData: AssetFormData = {
          front_url: frontUrl,
          inside_url: insideUrl,
        };

        const responseRecipient: ApiResponse<RecipientResponseData> = await saveRecipientAssets(
          projectId,
          assetFormData,
        );

        setBtlyUrl(responseRecipient.data?.url || '');
        hideLoadingScreen(appDispatch);
      };

      fetchDigitalAssets(images[0].url, images[1].url);
    }
  }, [images]);

  const isLastSlide = !(slideIndex < images.length - 1);
  const isFirstSlide = slideIndex === INITIAL_SLIDE_INDEX;

  const onEmailSplashContinue = () => {
    removeBeforeUnloadEvent();
    handleCloseAndKeepShopping(projectTypeCode as ProjectTypeCode);
  };

  const onEmailSplashPlayAnimations = () => {
    handlePlayAnimation();
  };

  const AnimationDialog = () => (
    <Dialog
      id={'play-animation-dialog'}
      isOpen={isAnimationDialogOpen}
      onClose={() => setIsAnimationDialogOpen(false)}
      closeButtonId={'close'}
      accentIcon={DialogIconNames.CardFeaturesBold}
      accentIconColor={BrandColors.White}
      hasGrayBackground={true}
      type={DialogContentType.FeatureModal}
      footerType={DialogFooterType.Passive}
      disableDialogScroll={true}
      cancelButton={<CancelButton click={handleCloseAnimationDialog} />}
      title={`${t('previewView.previewAnimationModalTitle')}`}
      locale={region}
    >
      <Suspense fallback={AnimationPreviewLoader()}>
        <AnimationPreviewComponent panelImages={animationPreviewImages} />
      </Suspense>
    </Dialog>
  );

  if (showEmailConfirmation) {
    return (
      <>
        <EmailSplash onContinue={onEmailSplashContinue} onPlayAnimations={onEmailSplashPlayAnimations} />
        <AnimationDialog />
      </>
    );
  }

  const playAnimationButton = (
    <Button
      testId="play-animation-button"
      addClass={styles['animation-button-mobile']}
      click={handlePlayAnimation}
      startIcon={{ name: IconNames.PlaysmallBold }}
      disabled={!btlyUrl}
      mode={ButtonModes.Secondary}
    >
      {playAnimationMessage}
    </Button>
  );
  const handleConsumerReviewCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsumerReviewCheck(event.target.checked);
  };

  const ConsumerReviewCheck = (): ReactElement => (
    <div className={styles['consumer-description-container']}>
      <input
        type="checkbox"
        id="acknowledge-checkbox"
        className={styles['consumer-review-checkbox']}
        data-testid="consumer-review-checkbox"
        checked={consumerReviewCheck}
        onChange={handleConsumerReviewCheck}
      />
      <label htmlFor="acknowledge-checkbox">{`${t('previewView.consumerReviewCheckbox')}`}</label>
    </div>
  );

  return (
    <Layout hideToolbar>
      <SEO title={`${t('previewView.title')}`} description={`${t('previewView.seo')}`} />
      {isDialogOpen && (
        <Dialog
          addDialogWrapperClass={styles['consumer-review-dialog']}
          id={'add-to-cart-dialog'}
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          closeButtonId={'close'}
          accentIcon={DialogIconNames.CardFeaturesBold}
          accentIconColor={BrandColors.White}
          hasGrayBackground={true}
          type={DialogContentType.Modal}
          title={`${t('previewView.consumerReviewTitle')}`}
          footerType={DialogFooterType.Cancel}
          disableDialogScroll={true}
          actionButton={<ActionButton click={() => handleSubmit(quantity)} />}
          cancelButton={<BackToPreviewButton click={() => setIsDialogOpen(false)} />}
          locale={region}
        >
          <p className={styles['consumer-review-description']} data-testid="consumer-review-description">
            {`${t('previewView.consumerReviewDescription')}`}
          </p>
          <ConsumerReviewCheck />
        </Dialog>
      )}
      {IS_H_PLUS_ENABLED && isNoCrNoHOpen && (
        <NoCrNoH isOpen={isNoCrNoHOpen} setIsOpen={setIsNoCrNoHOpen} isLoyaltyCtaVisible={isNoCrNoHLoyaltyVisible} />
      )}
      {IS_H_PLUS_ENABLED && isEcardLimitReachedOpen && (
        <ECardLimitModal isOpen={isEcardLimitReachedOpen} onClose={() => setIsEcardLimitReachedOpen(false)} />
      )}
      {isAnimationDialogOpen && <AnimationDialog />}
      <div data-testid="preview-view" className={styles.preview}>
        <div className={styles['card-container']}>
          <div className={styles['prev-button']}>
            {!isFirstSlide && (
              <IconButton
                variant={IconButtonVariants.Circular}
                icon={IconNames.ArrowsTailLeftBold}
                click={() => handlePrevClick(slideIndex)}
                ariaLabel="Last slide button"
                iconClasses={styles['icon']}
                addClass={styles['button']}
                iconColor={IconButtonBrandColors.Purple}
                size={10}
                data-testid="prev-icon-button"
              />
            )}
          </div>
          <div className={styles['carousel-container']}>
            <Carousel
              disableKeyboardControls={true}
              slidesToScroll={1}
              currentSlideIndex={slideIndex}
              onAfterSlide={handleChangeSlide}
              slideClass={styles.slide}
              addClass={styles.carousel}
            >
              {images.length > 0 &&
                images.map((image, index) => {
                  return (
                    <Image
                      // image.dataURL for FE previews
                      src={image.photoZoneTemplate || image.dataURL}
                      alt="Front item"
                      pictureClass={styles['preview-image']}
                      addClass={styles.image}
                      lazyLoad={false}
                      key={`preview-image-${index}`}
                    />
                  );
                })}
            </Carousel>
          </div>
          <div className={styles['next-button']}>
            {!isLastSlide && (
              <IconButton
                variant={IconButtonVariants.Circular}
                icon={IconNames.ArrowsTailRightBold}
                iconColor={IconButtonBrandColors.Purple}
                size={10}
                click={() => handleNextButtonClick(slideIndex)}
                ariaLabel="Next slide button"
                iconClasses={styles['icon']}
                addClass={styles['button']}
                data-testid="next-icon-button"
              />
            )}
          </div>
        </div>

        <CardSelectBar
          faces={images.filter(isNotNull)}
          slideIndex={slideIndex}
          handleIndexChange={handleImageDotClick}
          mode="preview"
        />

        <div className={styles['play-button-mobile']}>{isDigitalGreetings && playAnimationButton}</div>

        <div className={styles['navigation-buttons']}>
          <Button mode={ButtonModes.TextLink} click={goBack} addClass={styles['edit-button']}>
            {t('previewView.edit')}
          </Button>

          <div className={styles['action-buttons']}>
            {isDigitalGreetings && React.cloneElement(playAnimationButton, { addClass: styles['animation-button'] })}
            <Button disabled={isSendingemail} testId="next-button" click={() => handleNextButtonClick(slideIndex)}>
              {slideIndex < images.length - 1 ? t('previewView.next') : continueMessage}
            </Button>
          </div>

          {!isSystemErrorOpen && (
            <AddToCart
              basket={basket}
              recentBasket={recentBasket}
              basketError={basketError}
              closeModalOverride={closeModalOverride}
              keepShoppingOverride={keepShoppingOverride}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
