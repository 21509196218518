import {
  SaveAddressResponse,
  Address,
  AddressResponseData,
  AddressTypeCode,
  FormValidation,
  AddressForm,
  EmailAddressForm,
  QuickAddress,
  QuickAddressResponse,
  QuickAddressSubmitData,
  QuickAddressesResponse,
  GetAddresses,
  UpdateAddress,
  QuickAddresses,
  ErrorType,
  AddressTypes,
  BaseAddress,
  DigitalAddress,
  AssetFormData,
  RecipientResponseData,
} from './addresses';
import {
  MiniCart,
  ProductLineItem,
  CartData,
  RequestBody,
  AddToCartResponse,
  RecentBasket,
  AddToCartParams,
  MockCartUtility,
  LineItemAdded,
} from './basket';
import {
  CanvasJson,
  FabricObject,
  ObjectJson,
  OmitMethods,
  Breakpoints,
  FabricTextBox,
  CustomFabricObject,
  CustomFabricImage,
  EditCanvasJson,
} from './canvas';
import { CardType } from './card';
import {
  CardFace,
  CardDimensions,
  CardEditableArea,
  CardFacePreviewImage,
  CardFaceData,
  CardText,
  EditableTextSettings,
  EditableZoneSettings,
} from './card-face';
import { GetProjectAsset, ProjectAssetData, GetProjectResponseData } from './digital-greetings';
import { ConfirmProjectDetailsRequestBody, DigitalOrderRequestBody } from './digital-orders';
import { EditableArea } from './editable-area';
import { Envelope, EnvelopePreviewResponse, EnvelopePreviewBody, SelectedEnvelope } from './envelope';
import { Font, OpenTypeFont } from './font';
import { FulfillmentResponse, PreviewResponseData, PreviewFormData, AccountProfileResponse } from './fulfillment';
import {
  ImageResponse,
  LinkPhotoResponse,
  CleanUpImageResponse,
  CropAndRotateResponse,
  linkPhotoFormData,
  cleanUpFormData,
  cropAndRotateFormData,
  PreviewAssetType,
  PreviewLabel,
} from './images';
import { InformationDetails } from './information-details';
import { InitializationData, TemplateData, ProjectTypeCode, InitBody, ProjectAsset } from './initialization-data';
import { Page, PageItem, Layer } from './page';
import { PhotoTextZoneConfig, PhotoTextZoneDefaultSettings, PhotoTextZoneSettings } from './photo-text-zone';
import { PhotoZone } from './photo-zone';
import { RegionalCodesList } from './regions-list';
import { DEFAULT_PROJECT_NAMES } from './save-project';
import {
  Error,
  ApiResponse,
  ErrorResponse,
  GetPreviewProps,
  Meta,
  SuccessNoContentResponse,
  ErrorResponseData,
} from './services';
import { SpellcheckErrors, SpellcheckSuggestion, MappedSpellcheckSuggestion, SpellcheckActions } from './spellcheck';
import { TextZone } from './text-zone';
import { CommonProperties } from './utility';

declare global {
  interface Window {
    fabric: any;
    _satellite?: any;
  }
}

window.fabric = window.fabric || {};

export type {
  Envelope,
  SelectedEnvelope,
  Page,
  PageItem,
  Layer,
  Font,
  OpenTypeFont,
  PhotoZone,
  TextZone,
  EditableArea,
  InitializationData,
  InformationDetails,
  CardFace,
  CardDimensions,
  CardEditableArea,
  CardFacePreviewImage,
  CardFaceData,
  CardText,
  EditableTextSettings,
  EditableZoneSettings,
  ImageResponse,
  LinkPhotoResponse,
  SaveAddressResponse,
  BaseAddress,
  Address,
  DigitalAddress,
  FormValidation,
  AddressForm,
  EmailAddressForm,
  AddressResponseData,
  AddressTypeCode,
  FulfillmentResponse,
  PreviewResponseData,
  PreviewFormData,
  AccountProfileResponse,
  ProjectAssetData,
  GetProjectAsset,
  GetProjectResponseData,
  EnvelopePreviewResponse,
  EnvelopePreviewBody,
  MiniCart,
  ProductLineItem,
  QuickAddress,
  QuickAddressResponse,
  QuickAddressesResponse,
  CleanUpImageResponse,
  CropAndRotateResponse,
  linkPhotoFormData,
  cleanUpFormData,
  cropAndRotateFormData,
  QuickAddressSubmitData,
  TemplateData,
  CartData,
  RequestBody,
  AddToCartResponse,
  RecentBasket,
  AddToCartParams,
  MockCartUtility,
  GetAddresses,
  UpdateAddress,
  LineItemAdded,
  ProjectTypeCode,
  QuickAddresses,
  Error,
  ApiResponse,
  ErrorResponse,
  ErrorResponseData,
  GetPreviewProps,
  Meta,
  InitBody,
  ProjectAsset,
  SuccessNoContentResponse,
  CanvasJson,
  EditCanvasJson,
  FabricObject,
  ObjectJson,
  OmitMethods,
  Breakpoints,
  FabricTextBox,
  CustomFabricObject,
  CustomFabricImage,
  CommonProperties,
  AssetFormData,
  RecipientResponseData,
  DigitalOrderRequestBody,
  ConfirmProjectDetailsRequestBody,
  PhotoTextZoneConfig,
  PhotoTextZoneDefaultSettings,
  PhotoTextZoneSettings,
  SpellcheckErrors,
  SpellcheckSuggestion,
  MappedSpellcheckSuggestion,
};

export {
  CardType,
  ErrorType,
  AddressTypes,
  DEFAULT_PROJECT_NAMES,
  RegionalCodesList,
  PreviewAssetType,
  PreviewLabel,
  SpellcheckActions,
};
