import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Theme } from '@hallmark/web.themes.theme-provider';
import styles from './App.module.scss';
import { SFCCRedirect } from './components/sfcc-redirect';
import { ContextProvider } from './context';
import { InitializationDataContext } from './context/data-context';
import { PODRoutes } from './routes';
import { preventUnload } from './utils';
import { getThemeForCurrentRegion } from './utils/get-theme-for-current-region';

const PrintOnDemand = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', preventUnload);
  }, []);

  const themeOverrides = getThemeForCurrentRegion();

  return (
    <BrowserRouter>
      <ContextProvider>
        <InitializationDataContext.Consumer>
          {() => (
            <Theme.ThemeProvider overrides={themeOverrides}>
              <div className={styles.app}>
                <Switch>
                  <Route path={`/card/customization`} key={'SFCCRedirect'} exact component={SFCCRedirect} />
                  {PODRoutes.map(({ route, name, exact, view }) => (
                    <Route path={`/card/customization${route}`} key={name} exact={exact} component={view} />
                  ))}
                </Switch>
              </div>
            </Theme.ThemeProvider>
          )}
        </InitializationDataContext.Consumer>
      </ContextProvider>
    </BrowserRouter>
  );
};

export default PrintOnDemand;
