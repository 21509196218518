import React, { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerPositions } from '@hallmark/web.core.feedback.drawer';
import { Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { useActiveCanvas } from '../../../hooks';
import { getSelectableObjectsIndices } from '../../../utils';
import { FooterContent } from './fragments/footer-content';
import { HeaderContent } from './fragments/header-content';
import { OrderDrawerProps } from './order-drawer-types';
import styles from './order-drawer.module.scss';

export const OrderDrawer = ({ isOpen, handleOrderChange, onClose, onSubmit }: OrderDrawerProps) => {
  const canvas = useActiveCanvas();
  const originalIndex = useRef(0);
  const { t } = useTranslation();
  const canvasObjectsIndices = useRef<number[]>([]);

  useMemo(() => {
    if (canvas?.current) {
      const activeObject = canvas.current.getActiveObject();
      const objects = canvas.current.getObjects();
      canvasObjectsIndices.current = getSelectableObjectsIndices(objects);

      if (activeObject) {
        // Set originalIndex to activeObject's position in array of objects
        const currentIndex = objects.findIndex((el) => el.name === activeObject.name);
        const mappedIndex = canvasObjectsIndices.current.indexOf(currentIndex);
        originalIndex.current = mappedIndex;
      }
    }
  }, []);

  const handleClose = () => {
    handleOrderChange(originalIndex.current);
    onClose();
  };

  const handleChange = (order: number) => {
    // Get the actual index of the object in the canvas by using the mapped indices
    const newOrderIndex = canvasObjectsIndices.current[`${order}`];
    handleOrderChange(newOrderIndex);
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Drawer
      hideCloseButton={true}
      showVeil={false}
      addClass={styles['order-drawer']}
      drawerPosition={DrawerPositions.Bottom}
      isOpen={isOpen}
      onClose={handleClose}
      role="toolbar"
      headerContent={<HeaderContent currentOrder={originalIndex.current} handleOrderChange={handleChange} />}
      footerContent={<FooterContent onClose={handleClose} onSubmit={handleSubmit} />}
    >
      <Typography variant={TypographyVariants.SRT}>{t('orderDrawer.orderDrawer')}</Typography>
    </Drawer>
  );
};
