import { CanvasDataTypes } from "../canvas";

export const defaultImageSettingsConfig = ({ event, data, isUK }) => ({
  left: event.e.offsetX * 2,
  top: event.e.offsetY * 2,
  name: data?.image_id,
  data: {
    type: CanvasDataTypes.UserImage,
  },
  crossOrigin: 'anonymous',
  borderColor: isUK ? '#333333' : '#604099',
  originX: 'center',
  originY: 'center',
})

export const scaleToHeight = 200;

export const scaleToWidth =200;
